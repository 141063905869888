import React, { Fragment, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import Switch from '../components/widgets/LabelSwitch';

import Scryfall from '../services/scryfall';
import SeventeenLands from '../services/seventeen-lands';

import { lookupByLowerCaseCardName } from '../helpers/card.helpers';
import { GIH_COUNT_PROPERTY, GIH_SET_PROPERTY, GIH_WR_PROPERTY } from '../constants';

export default function SeventeenLandsStats({ sets, cards, disabled, initializing, updateCardMetadataFn }) {
  const [embargoEndDatesBySet, setEmbargoEndDatesBySet] = useState({});
  const [isStatsSwitchOn, setStatsSwitch] = useState(false);
  const [isLoadingStats, setLoadingStats] = useState(false);
  const [format, setFormat] = useState('PremierDraft');
  // output messages
  const [warning, setWarning] = useState();
  const [error, setError] = useState();

  // Use a reference for the value of `isStatsSwitchOn` and `format` to see the freshest value
  // https://stackoverflow.com/a/61957390
  const statsSwitchRef = React.useRef(isStatsSwitchOn);
  statsSwitchRef.current = isStatsSwitchOn;
  const formatRef = React.useRef(format);
  formatRef.current = format;

  // reset the default state of the component
  const resetState = () => {
    setStatsSwitch(false);
    setFormat('PremierDraft');
    // clear warning and error messages
    setWarning(/* undefined */);
    setError(/* undefined */);
    // hide the 'Loading...' message on this component
    setLoadingStats(false);
  }

  // after initializing, always reset the switch
  useDeepCompareEffect(() => {
    // only reset if the effect has changed `initializing` to true
    if (initializing) {
      resetState();
    }
  }, [initializing, sets]);

  // is every `set` considered to be within the 17Lands embargo period?
  const isEverySetEmbargoed = () => Object.entries(
    toEmbargoDaysBySet(embargoEndDatesBySet)).every(
      ([set, days]) => isWithinEmbargoPeriod(days));

  // refresh the embargo end dates when the sets change
  useDeepCompareEffect(() => {
    // fetch the embargo end dates and merge with any existing embargo end dates
    fetchEmbargoEndDates(sets).then(endDatesBySet => {
      setEmbargoEndDatesBySet(prevEndDatesBySet => ({ ...prevEndDatesBySet, ...endDatesBySet }));
    })
    .catch(error => {
      console.error("Error while determining the 17Lands embargo period -", error);
    });
  }, /* dependencies = */ [sets]);

  // helper function to visually indicate that card GIH WR is loading, 
  // both in this widget and on the individual cards
  const showLoadingGIHWR = () => {
    // show 'Loading...' message on this component
    setLoadingStats(true);
    // set each individual card GIH_WR to `null` to indicate the GIH WR is loading but has no value
    // See `Card.maybeRenderGIHWR`
    updateCardMetadataFn(
      // [ { cardId: 0, GIH_WR_PROPERTY: null } ]
      cards.map(card => (
        {
          cardId: card.cardId,
          [GIH_WR_PROPERTY]: null
        }
      ))
    );
  }

  // helper function to clear the visual indicators that GIH WR is loading
  // both in this widget and on the individual cards that do NOT have `cardStatsData`
  const hideLoadingGIHWR = (cardStatsData) => {
    setLoadingStats(false);
    // find those cardIds that were NOT in the results; clear their GIHWR status
    const cardIdsWithGIHWR = new Set(cardStatsData.map(data => data.cardId));
    const cardIdsToClear = cards.flatMap(card => {
      if (cardIdsWithGIHWR.has(card.cardId)) return [];
      else return [card.cardId];
    });

    return clearCardStats(cardIdsToClear);
  }

  // helper function to clear the card stats
  const clearCardStats = (cardIdsToClear = cards.map(card => card.cardId)) => {
    return updateCardMetadataFn(
      // [ { cardId: 0, GIH_WR_PROPERTY: undefined, ... }, ... ]
      cardIdsToClear.map(cardId => (
        {
          cardId,
          [GIH_WR_PROPERTY]: undefined,
          [GIH_COUNT_PROPERTY]: undefined,
          [GIH_SET_PROPERTY]: undefined
        }
      ))
    );
  }

  // helper function to determine if the given format is still the active selection
  const isStillActive = (format) =>
    // is the switch still on and does the selected format match?
    statsSwitchRef.current && (format === formatRef.current);

  // helper function to apply the retrieved card stats, if still active
  const maybeApplyStats = (results, format) => {
    return Promise.resolve(mapCardIdsToStats(cards, results.statistics))
      .then((cardStatsData) => {
        // before updating, verify that the switch is not off and stats should still be shown
        // and that the selected format is the same as the loaded data
        if (isStillActive(format)) {
          updateCardMetadataFn(cardStatsData);
        }
        else {
          console.info("Dropping card stats data from now-inactive request", format);
        }
        // return the card stats data (for access to the cardIds being updated)
        // NOTE that this is NOT chained off of the actual call to `updateCardMetadataFn`
        return cardStatsData;
      });
  }

  // helper function to show error/warning messages, if still active
  const maybeShowMessages = (results, format) => {
    // exit early and show no messages if the switch is toggled *off*
    // or if the selected format is different from the loaded data
    if (!isStillActive(format)) {
      console.info("Skipping error messages from now-inactive request:", format);
      return; // exit
    }

    if (results.missing.length) {
      setWarning('No 17Lands data found for: ' + 
        results.missing.map(set => set.toUpperCase()).join(', '));
    }
    // No card statistics - set an error message and be done
    else if (!Object.keys(results.statistics).length) {
      setError('Failed to load 17Lands data');
      return; // exit
    }

    // Partial card statistics - set error and/or warning messages
    if (results.errors.length) {
      setError('Failed to load 17Lands data: ' + 
        results.errors.map(set => set.toUpperCase()).join(', '));
    }
  }

  // refresh the cards stats after the `isStatsSwitchOn` or `format` state have changed
  const refreshCardStats = (isStatsSwitchOn, formatName) => {
    // set the new values for `isStatsSwitchOn` and `format`
    setStatsSwitch(isStatsSwitchOn);
    setFormat(formatName);
    // clear warning and error messages
    setWarning(undefined);
    setError(undefined);
    // always clear any previous card stats when triggered
    // (either stat switch toggled or format changed)
    clearCardStats();
    // then, if the switch is on, load the card stats
    if (isStatsSwitchOn) {
      // visually indicate that card GIH WR is loading
      showLoadingGIHWR();
      // filter the `embargoDaysBySet` down to only those set codes NOT embargoed
      const embargoDaysBySet = toEmbargoDaysBySet(embargoEndDatesBySet);
      const setsNotEmbargoed = Object.entries(embargoDaysBySet)
        .filter(([set, days]) => !isWithinEmbargoPeriod(days))
        .flatMap(([set, days]) => set);

      // only load card stats for non-embargoed sets
      const cardStatsAndErrors = loadCardStats(setsNotEmbargoed, formatName);
      // handle results (card stats and errors)
      const showStats = cardStatsAndErrors.then(results => maybeApplyStats(results, formatName));
      const handleErrors = cardStatsAndErrors.then(results => maybeShowMessages(results, formatName));
      // set to no longer loading if this load is still active (i.e., no changes in selected format)
      Promise.all([showStats, handleErrors]).then(([cardStatsData, _]) => {
        if(isStillActive(formatName)) {
          // hide visual indicators that card GIH WR is loading
          hideLoadingGIHWR(cardStatsData);
        }
      });
    }
    // otherwise just set loading status to false
    else setLoadingStats(false);
  };

  /*
   * Determine if this GIH WR stats component is disabled:
   *  - Component is `disabled` or `initializing` (in props)
   *  - No set specified (in props)
   *  - Within the 17Lands embargo period for ALL sets
   */
  const isDisabled = disabled || initializing || !sets?.length || isEverySetEmbargoed();

  // render the component
  return <div className="seventeenlands-gihwr">
      <Switch 
          className="seventeenlands-gihwr-show-switch"
          disabled={isDisabled} 
          onChange={selected => refreshCardStats(selected, format)} 
          checked={isStatsSwitchOn}>
        Show card <abbr className="gihwr" title="Games in Hand Win Rate">GIH&nbsp;WR</abbr>
        <br/>
        from {/* eslint-disable-line */} <a 
            href="https://www.17lands.com/card_data" 
            target="_blank" 
            rel="noopener">
          17Lands</a>
      </Switch>
      {isStatsSwitchOn && renderFormatDropDown(format, (formatName) => refreshCardStats(isStatsSwitchOn, formatName))}
      {isLoadingStats && <div className="message pulse"><span>Loading...</span></div>}
      {error && <div className="message error"><span>{error}</span></div>}
      {warning && <div className="message warning"><span>{warning}</span></div>}
      {maybeRenderEmbargoMessage(embargoEndDatesBySet, isStatsSwitchOn)}
    </div>;
}

function renderFormatDropDown(format, onFormatChange) {
  return <div className="seventeenlands-gihwr-format">
      <select value={format} onChange={event => onFormatChange(event.target.value)}>
        <option value="PremierDraft">Premier Draft</option>
        <option value="TradDraft">Traditional Draft</option>
        <option value="QuickDraft">Quick Draft</option>
        <option value="Sealed">Sealed</option>
        <option value="TradSealed">Traditional Sealed</option>
      </select>
    </div>;
}

/**
 * @param {Integer} days the number of days remaining within the embargo period
 * @returns true if the given number of days is considered within the embargo period 
 */
function isWithinEmbargoPeriod(days) {
  return !Number.isInteger(days) || days > 0;
}

function toEmbargoDaysBySet(embargoEndDatesBySet) {
  // calculate the embargo days remaining based on each set's embargo end date
  const embargoDaysBySet = Object.keys(embargoEndDatesBySet).reduce((merged, set) =>
    ({ ...merged, [set] : embargoDaysRemaining(embargoEndDatesBySet[set]) }),
    /* initialValue = */ {});

  return embargoDaysBySet;
}

function maybeRenderEmbargoMessage(embargoEndDatesBySet, isStatsSwitchOn) {
  // calculate the embargo days remaining based on each set's embargo end date
  const embargoDaysBySet = toEmbargoDaysBySet(embargoEndDatesBySet);
  // filter the `embargoDaysBySet` down to only those set codes within the embargo period
  const setsWithinEmbargo = Object.entries(embargoDaysBySet)
    .filter(([set, days]) => isWithinEmbargoPeriod(days))
    .flatMap(([set, days]) => set);

  // if all sets are embargoed, show the message
  const allSets = Object.keys(embargoEndDatesBySet);
  if (setsWithinEmbargo.length && setsWithinEmbargo.length === allSets.length) {
    return renderEmbargoMessage(embargoDaysBySet);
  }
  // if some (but not all) sets are embargoed, wait until enabled to show the embargo message
  else if (isStatsSwitchOn && setsWithinEmbargo.length) {
    return renderEmbargoMessage(embargoDaysBySet);
  }
  else {
    // no embargo message
    return <></>;
  }
}

function renderEmbargoMessage(embargoDaysBySet) {
  // helper function to render "day" or "days"
  const days = (numDays) => numDays === 1 ? "day" : "days";

  const setsAndDays = Object.keys(embargoDaysBySet)
    .filter(set => {
      const daysRemaining = embargoDaysBySet[set];
      // if daysRemaining is a number (not undefined) and is less than 1,
      // then filter that set out from the embargo message
      return !(Number.isInteger(daysRemaining) && daysRemaining < 1);
    })
    .map((set, i) => {
      const daysRemaining = embargoDaysBySet[set];
      // if `daysRemaining` is null/undefined, we don't (yet?) know the embargo end date
      return <Fragment key={i}>{i > 0 && ', '}<span style={{ whiteSpace: 'nowrap' }}>
          {daysRemaining ?? '?'} {days(daysRemaining)} ({set.toUpperCase()})
        </span></Fragment>;
    });

  return <div className="message error embargo tooltip">
    <span title="17Lands has an Embargo Period for third-party &#013; tools until the 12th day after a new set release.">
      17Lands card data will be available in {setsAndDays}
      {/* eslint-disable-line */}&nbsp;<a 
          target="_blank" rel="noopener" 
          href="https://www.17lands.com/usage_guidelines">[?]</a>
    </span>
  </div>;
}

/**
 * @param {String[]} sets an array of the set codes for which to determine the embargo end date
 * @returns a {@link Promise} containing a map of the provided set codes to the embargo end date
 */
async function fetchEmbargoEndDates(sets) {
  // no need to check if no set is specified
  if (!sets?.length) {
    console.info("No sets specified; skip check for 17Lands embargo period.")
    return Promise.resolve({});
  }
  else /* if (!this.state.embargoEndDate) */ {
    console.info("Checking 17Lands embargo period for", sets.join(', '))
    // 17Lands has a 12 day Embargo Period: https://www.17lands.com/usage_guidelines
    const promiseEmbargoEndDates = sets.map(set => 
      Scryfall.getReleaseDate(set)
        .then(releaseDate => ({ [set]: toEmbargoEndDate(releaseDate) }))
        .catch(error => {
          // log any error and map the corresponding set to a `null` embargo end date
          console.warn(`Failed to load the ${set.toUpperCase()} set release date from Scryfall -`,
            error.message);
          return { [set]: null };
        })
    );
    return Promise.all(promiseEmbargoEndDates)
      .then(endDatesBySet => 
        // merge the returned maps of `{ set : Date }` objects
        endDatesBySet.reduce((merged, setEndDate) => ({ ...merged, ...setEndDate })))
  }
}

function toEmbargoEndDate(releaseDate) {
  // the digital release is (always?) three days before the official release date
  // (e.g., Tuesday before the Friday)
  const digitalReleaseDate = new Date(releaseDate);
  digitalReleaseDate.setDate(digitalReleaseDate.getDate() - 3);
  
  // Add 11 days to find the 12th day after set release and compare with current date
  const embargoEndDate = new Date(digitalReleaseDate);
  embargoEndDate.setDate(digitalReleaseDate.getDate() + 11);
  return embargoEndDate;
}

/**
 * Given an embargo end date, calculate the number of days remaining.
 * Returns `null` or `undefined` when given either as the embargo end date.
 * 
 * @param {Date} embargoEndDate the embargo end date
 * @returns an integer representing the number of days remaining
 */
function embargoDaysRemaining(embargoEndDate) {
  if (embargoEndDate) {
    const now = new Date();
    const embargoDaysRemaining = daysBetween(now, embargoEndDate);
    return embargoDaysRemaining;
  }
  else if (embargoEndDate === null) return null;
  return undefined;
}

function daysBetween(first, second) {
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // set both dates to midnight to disregard time of day
  first.setHours(0, 0, 0);
  second.setHours(0, 0, 0);

  // Calculate the difference in milliseconds; never negative
  const differenceMillis = Math.max(0, second - first);

  // Convert back to days and return
  return Math.round(differenceMillis / ONE_DAY);
}

/**
 * Retrieve the card GIH WR statistics from 17Lands for each set.
 * 
 * @param {String[]} sets the set codes for which to fetch 17Lands card data
 * @param {String} format the format for which to fetch card data
 * @returns a {@link Promise} containing an array of `statistics` retrieved for each set and an
 * array of `errors` containing the set codes whose card stats could not be retrieved:
 * ```
   {
     statistics: { 'mh3' : [ { 'name': 'Nulldrifter', ...}, ...], ...}, 
     errors: [ 'otj', ...]
   }
   ```
 */
function loadCardStats(sets, format) {
  // wait for all card set stats promises to complete, regardless of whether or not one rejects
  return Promise.allSettled(
    sets.map(set => 
      SeventeenLands.cardData(set, format)
        // return the card statistics paired with the set
        .then(data => ({ set, format, statistics: data }))
        // add the set to any errors and rethrow
        .catch(error => {
          error.set = set;
          error.format = format;
          throw error;
        })
    ))
    .then(extractStatsAndErrors);
}

/**
 * Extract the 17Lands card stats and any errors from an array of results 
 * from `Promise.allSettled`.
 * 
 * @param {Object[]} allSettledResults 
 * @returns a {@link Promise} containing an array of `statistics` retrieved for each set and an
 * array of `errors` containing the set codes whose card stats could not be retrieved:
 * ```
   {
     statistics: { 'mh3' : [ { 'name': 'Nulldrifter', ...}, ...], ...}, 
     errors: [ 'otj', ...]
   }
   ```
 */
function extractStatsAndErrors(allSettledResults) {
  const cardStatsBySet = allSettledResults.reduce((combinedResults, result) => {
    if (result.status === 'fulfilled') {
      const set = result.value?.set;
      // filter out any empty card statistics and log a warning
      if (result.value?.statistics?.length) {
        if (combinedResults.statistics.hasOwnProperty(set)) {
          console.warn(`Retrieved multiple 17Lands card data for [${set?.toUpperCase()}]`, 
          combinedResults.statistics[set], result.value.statistics);
          combinedResults.statistics[set] = combinedResults.statistics[set].concat(result.value.statistics);
        }
        else {
          combinedResults.statistics[set] = result.value.statistics;
        }
      }
      else {
        combinedResults.missing = combinedResults.missing.concat(set);
        console.warn(`17Lands '${result.value?.format}' card stats not found for [${set?.toUpperCase()}]`);
      }
    } else { // result.status === 'rejected'
      // track any sets that had an error while fetching and log an error
      combinedResults.errors = combinedResults.errors.concat(result.reason.set);
      console.error(`Error while loading 17lands '${result.reason.format}' card data ` +
        `for [${result.reason.set.toUpperCase()}] -`, result.reason);
    }        
    return combinedResults;
  }, /* initialValue = */ { statistics: {}, errors: [], missing: [] });

  return Promise.resolve(cardStatsBySet);
}

/**
 * Map the card Ids for every provided card to the corresponding card stats 
 * in the provided `cardStatsBySet` (matched by lower case card name).
 * 
 * @param {Object[]} cards the cards in the card pool
 * @param {Object} cardStatsBySet mapping from card set to an array of 17Lands card stats
 * @returns a {@link Promise} containing an array of each `cardId` and the corresponding 
 *          card stats data (e.g., GIH_WR_PROPERTY)
 */
function mapCardIdsToStats(cards, cardStatsBySet) {
  if (Object.keys(cardStatsBySet).length === 0) {
    console.warn(`No 17Lands card stats loaded!`);
    return Promise.resolve([]);
  }
  // determine if there are multiple card sets represented in the pool; considering
  // sanitized sets to *not* consider, e.g., special guests 'SPG' as a distinct set
  // (used when determining if the set icon is shown on the Card)
  const sanitizedCardSets = SeventeenLands.sanitizeAllCardSets(cards);
  const hasMultipleSanitizedSets = sanitizedCardSets.length > 1;

  // organize all card stats first by card name, then by set
  const cardStatsByNameAndSet = Object.entries(cardStatsBySet).reduce((acc, [set, setStats]) => {
    setStats.forEach(cardStats => {
      const name = cardStats.name.toLowerCase();
      acc[name] = acc[name] || {}; // grab the stats already stored at this `name` or empty
      if (acc[name][set]) {
        console.warn(`Found multiple card stats for ${cardStats.name} in [${set?.toUpperCase()}]`);
      }
      acc[name][set] = cardStats;
    });
    return acc;
  }, /* initialValue = */ {});

  const cardIdsWithStats = cards.flatMap(card => {
    // try to lookup the card stats for this card by it's name and set;
    // `SeventeenLands.normalizeCardSet` will resolve bonus sheet sets, etc.
    let statsSet = SeventeenLands.normalizeCardSet(card);
    const cardStatsBySet = lookupByLowerCaseCardName(cardStatsByNameAndSet, card);

    if (!cardStatsBySet) {
      console.debug(`No 17Lands card stats for card named [${card.name}]`);
      return []; // exit early; filtered out by flatMap
    }

    // identify the 17Lands statistics for this card's specific set (if available)
    let cardStats = cardStatsBySet[statsSet];
    // cardStats could still be undefined!
    if (!cardStats) {
      console.warn(`No 17Lands card stats for card named [${card.name}] ` +
        `from set [${card.set?.toUpperCase()}]`);

      // identify 17Lands statistics for this card from *any* set already retrieved
      const cardStatsSets = Object.keys(cardStatsBySet);
      if (cardStatsSets.length) {
        statsSet = cardStatsSets[0];
        console.debug(`Using 17Lands card stats for card named [${card.name}] ` +
          `from set [${statsSet?.toUpperCase()}]` +
          ((cardStatsSets.length > 1)
            ? '; other options: ' + cardStatsSets.map(set => `[${set.toUpperCase()}]`).join(', ')
            : ''));
            cardStats = cardStatsBySet[statsSet];
      }
      else {
        console.warn(`No 17Lands card stats for card named [${card.name}]`);
        return []; // exit early; filtered out by flatMap
      }
    }

    // stats are no longer empty if we find a defined `ever_drawn_win_rate`
    // AND the game count is at least 200 (same filter that 17Lands uses)
    let gih_wr = cardStats.ever_drawn_win_rate;
    const smallSample = cardStats.ever_drawn_game_count < 200;
    // use NaN for cards with small sample statistics or that are provided as `null`
    if (smallSample || gih_wr === null) {
      gih_wr = NaN;
    }

    // check if the card stats set is different from the actual card's set
    const isCardSetMismatch = statsSet !== card.set;
    
    // special handling for Alchemy (Y-prefixed) card sets
    if (statsSet.length > 3 && statsSet.startsWith('y')) {
      statsSet = statsSet.substring(1);
    }
    
    return [{
      cardId: card.cardId,
      [GIH_WR_PROPERTY]: gih_wr,
      [GIH_COUNT_PROPERTY]: cardStats.ever_drawn_game_count,
      // only include the set if there are multiple sets to discriminate among
      [GIH_SET_PROPERTY]: (hasMultipleSanitizedSets || isCardSetMismatch) ? statsSet : undefined
    }];
  });

  return Promise.resolve(cardIdsWithStats);
}

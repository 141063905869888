import React from 'react';

import TierListRatings from './TierListRatings';
import SeventeenLandsStats from './SeventeenLandsStats';

import SeventeenLands from '../services/seventeen-lands';

import { isStandardBasicLand } from '../helpers/card.helpers';

export default class CardSetTech extends React.Component {

  render() {
    const cardsNonBasics = this.props.cards.filter(c => !isStandardBasicLand(c));
    // determine which card set(s) to provide as display options
    const setCodes = this.determineCardSets(cardsNonBasics);

    return <>
      <SeventeenLandsStats
        sets={setCodes}
        disabled={this.props.disabled}
        initializing={this.props.initializing}
        cards={cardsNonBasics} 
        updateCardMetadataFn={this.props.updateCardMetadataFn}
      />
      <TierListRatings
        sets={setCodes}
        disabled={this.props.disabled}
        initializing={this.props.initializing}
        cards={cardsNonBasics}
        updateCardMetadataFn={this.props.updateCardMetadataFn}
        onAddCustomRatings={this.props.onAddCustomRatings}
      />
    </>;
  }

  /**
   * @returns a sorted list of card sets based on `this.props.cards`
   */
  determineCardSets(cards) {
    const cardSets = new Set(SeventeenLands.sanitizeAllCardSets(cards));
    // sort the remaining set codes alphabetically
    return Array.from(cardSets).sort();
  }

}
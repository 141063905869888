import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes }         from '@fortawesome/free-solid-svg-icons/faTimes';

import LabelRadio from './LabelRadio';

import SeventeenLands from '../../services/seventeen-lands';

const LOCAL_STORAGE_RATINGS_CUSTOM = 'ratings-custom';
export const SOURCE_CUSTOM_SEVENTEENLANDS = 'seventeenlands';
export const SOURCE_CUSTOM_SEALEDDECKTECH = 'sealeddecktech';

const EVENT_STORAGE_RATINGS_CUSTOM = 'storage:ratings-custom';

/**
 * Store the SealedDeck.Tech tier list ID locally as the source for custom card ratings
 * 
 * @param {String} tier_list_id 
 */
export function saveSealedDeckTechCustomRatings(tier_list_id) {
  if (tier_list_id) {
    localStorage.setItem(LOCAL_STORAGE_RATINGS_CUSTOM, 
      JSON.stringify({ [SOURCE_CUSTOM_SEALEDDECKTECH]: tier_list_id }));
    // dispatch a ratings storage change event, whether the value itself has changed or not
    triggerRatingsStorageEvent();
  }
  else deleteCustomRatings();
}

/**
 * Store the 17Lands tier list ID locally as the source for custom card ratings
 * 
 * @param {String} tier_list_id 
 */
export function saveSeventeenLandsCustomRatings(tier_list_id) {
  if (tier_list_id) {
    localStorage.setItem(LOCAL_STORAGE_RATINGS_CUSTOM, 
      JSON.stringify({ [SOURCE_CUSTOM_SEVENTEENLANDS]: tier_list_id }));
    // remove any locally cached ratings for this tier list so that it reloads
    SeventeenLands.clearCardTiersLocal(tier_list_id);
    // dispatch a ratings storage change event, whether the value itself has changed or not
    triggerRatingsStorageEvent();
  }
  else deleteCustomRatings();
}

/**
 * Delete any custom ratings stored in local storage.
 */
export function deleteCustomRatings() {
  localStorage.removeItem(LOCAL_STORAGE_RATINGS_CUSTOM);
  // dispatch a ratings storage change event
  triggerRatingsStorageEvent();
}

/**
 * Dispatch a custom event type for a CustomRatings storage change event
 */
function triggerRatingsStorageEvent() {
  window.dispatchEvent(new Event(EVENT_STORAGE_RATINGS_CUSTOM));
}

/**
 * @returns the custom ratings object stored in local storage, mapping a ratings 
 * source name to the corresponding tier list IDs.  Should only include one item:
 * ```
  { seventeenlands: "xxx" }
  { sealeddecktech: "xxx" }
  ```
*/
export function customRatingsSourceId() {
  const localRatingsCustom = localStorage.getItem(LOCAL_STORAGE_RATINGS_CUSTOM);
  return localRatingsCustom ? JSON.parse(localRatingsCustom) : undefined;
}

/**
 * @returns {boolean} true if a custom tier list id is stored in local storage
 */
export function hasCustomRatingsId() {
  const customRatingsSource = customRatingsSourceId();
  return (customRatingsSource?.[SOURCE_CUSTOM_SEVENTEENLANDS] 
      || customRatingsSource?.[SOURCE_CUSTOM_SEALEDDECKTECH]);
}

/**
 * @returns A component that toggles between a radio button for selecting custom tier list ratings 
 * (which are stored in local storage) and a button to trigger an action for adding custom ratings
 * when no custom ratings source is stored in local stoarge.
 */
export default function CustomRatingsRadio({ checked, value, disabled, initializing, onChange, onDelete, onAddCustomRatings }) {
  const [ratingsSource, setRatingsSource] = useState();
  const [ratingsSetCodes, setRatingsSetCodes] = useState();

  // run this effect once on first render to set up the event listener
  useEffect(() => {
    function refreshFromLocalStorage() {
      const localRatingsCustom = localStorage.getItem(LOCAL_STORAGE_RATINGS_CUSTOM);
      const customRatings = localRatingsCustom ? JSON.parse(localRatingsCustom) : undefined;
      setRatingsSource(customRatings);

      if (customRatings?.[SOURCE_CUSTOM_SEVENTEENLANDS]) {
        // if custom ratings are specified, determine the card set(s) and update the state
        const tierListId = customRatings[SOURCE_CUSTOM_SEVENTEENLANDS];
        return SeventeenLands.cardTiers(tierListId)
          .then(card_tiers => {
            const setCodes = SeventeenLands.setsFromCardTiers(card_tiers);
            setRatingsSetCodes(setCodes);
            return { sets: setCodes, tier_list_id: tierListId };
          });
      }
      else {
        // otherwise clear the set code and resolve as `undefined`
        setRatingsSetCodes(/* undefined */);
        return Promise.resolve(undefined);
      }
    }

    // fetch custom `ratingsSource` data from localStorage on first render
    refreshFromLocalStorage();

    function handleLocalStorageChange() {
      // trigger the appropriate callback function before refreshing the card ratings data
      if (hasCustomRatingsId()) onChange();
      else onDelete();

      return refreshFromLocalStorage();
    }

    // add an event listener for localstorage to refresh the state on future changes
    window.addEventListener(EVENT_STORAGE_RATINGS_CUSTOM, handleLocalStorageChange);

    return () => {
      // remove the event listener on cleanup
      window.removeEventListener(EVENT_STORAGE_RATINGS_CUSTOM, handleLocalStorageChange);
    };
  }, [onChange, onDelete]);

  // is a custom ratings source defined?
  if (!ratingsSource) {
    // render a button to add a custom ratings source
    return <>
      <button className="add-custom-ratings" 
          disabled={initializing}
          onClick={onAddCustomRatings}>
        Add Custom Ratings
      </button>
    </>;
  }
  else {
    // construct 17Lands link;
    // generate only one link for the first set code (all data is from the same tier list URL)
    const sourceLink = <>
        17Lands: {/* eslint-disable-line */} <a className="ratings-set-code" 
            href={SeventeenLands.tierListUrl(ratingsSource[SOURCE_CUSTOM_SEVENTEENLANDS])} 
            target="_blank" rel="noopener">
          {ratingsSetCodes?.length ? ratingsSetCodes[0].toUpperCase() : '???'}
        </a>
      </>;

    return <>
      <LabelRadio
          name="ratings"
          id="custom"
          disabled={disabled}
          value={value}
          checked={checked}
          onChange={onChange}>
        {sourceLink}
        <br/>
        <span className="buttons-adjacent">
          <button className="ratings-custom-edit" 
              disabled={initializing}
              onClick={onAddCustomRatings}>
            Custom Ratings
          </button>
          <button className="ratings-custom-delete" 
              disabled={initializing}
              onClick={deleteCustomRatings} >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </span>
      </LabelRadio>
    </>;
  }
};
